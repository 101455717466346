const ERROR_CODES_COGNITO = [
  "NotAuthorizedException",
  "UserNotFoundException",
  "InvalidPasswordException",
  "CodeMismatchException",
  "LimitExceededException",
  "Unknown",
  "UsernameExistsException",
  "InvalidParameterException",
  "PasswordResetRequiredException",
  "UserNotConfirmedException",
  "ExpiredCodeException",
  "UserAlreadyAuthenticatedException",
] as const;

export type CognitoErrorCode = ListItem<typeof ERROR_CODES_COGNITO>;

export const isCognitoErrorCode = (code: string): code is CognitoErrorCode => {
  const allCodes = new Set<string>(ERROR_CODES_COGNITO);

  return allCodes.has(code);
};

export const IDENTITY_EMAIL_NOT_FOUND = "Cognito email could not be found.";
export const isIdentityEmailNotFoundError = (error: unknown) => {
  return error instanceof Error && error.message === IDENTITY_EMAIL_NOT_FOUND;
};

export const getTokenErrorReason = (error: unknown) => {
  if (error instanceof Error && isCognitoErrorCode(error.name) && error.name === "NotAuthorizedException") {
    return "SESSION_EXPIRED" as const;
  }

  if (error === "No current user") {
    return "NO_CURRENT_USER" as const;
  }

  if (isIdentityEmailNotFoundError(error)) {
    return "IDENTITY_EMAIL_NOT_FOUND" as const;
  }

  return undefined;
};
