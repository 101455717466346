import { useState, useCallback, ComponentProps, useEffect, ReactNode, FC } from "react";
import { useDebouncedCallback } from "use-debounce";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { SEARCH_DEBOUNCE_DELAY_MS } from "@libs/utils/constants";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as SearchIcon } from "@libs/assets/icons/search.svg";
import { ReactComponent as RemoveIcon } from "@libs/assets/icons/cancel-small.svg";
import { ReactComponent as FiltersIcon } from "@libs/assets/icons/adjust.svg";
import { Button } from "@libs/components/UI/Button";
import { Pill } from "@libs/components/UI/Pill";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";

import { formatNumberWithCommas } from "@libs/utils/formatNumber";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { FilterComponentProps, FilterInput } from "@libs/components/UI/queryFilterPillsUtils";
import { QueryFilterPills } from "@libs/components/UI/QueryFilterPills";

export interface QueryFiltersProps<T extends FilterInput> {
  bulkActions?: React.ReactNode;
  emptyParams: Partial<T>;
  filters: FilterComponentProps<T>[];
  onOpenFlyover?: Func;
  onUpdateParams: (keyValues: Partial<T>) => void;
  params: T;
  queries?: ComponentProps<typeof QueryResult>["queries"];
  searchParamKey?: keyof T;
  selectedCount?: number;
  totalElements: number;
  filterText?: string;
  horizontalPadding?: string;
  searchPlaceholder?: string;
}

export const ActionsBarContainer: FC<{
  className?: string;
  children: ReactNode;
  horizontalPadding?: string;
  searchFilter?: ReactNode;
}> = ({ className, horizontalPadding, children, searchFilter }) => (
  <div
    className={cx(
      `flex
       items-start
       justify-between
       py-2
       gap-x-1
       border-b
       border-slate-300
       sticky
       top-0`,
      horizontalPadding ?? "px-4",
      className
    )}
  >
    <div className="flex items-start gap-x-1">{children}</div>
    <div className="flex gap-x-1">{searchFilter}</div>
  </div>
);

export const BulkActions: FC<{ children: ReactNode; selectedCount?: number }> = ({
  selectedCount,
  children,
}) => (
  <div className="flex items-center h-8 gap-x-1">
    {children}
    {selectedCount ? (
      <Pill round={false} theme="slate100" size="md">
        {formatNumberWithCommas(selectedCount)} Selected
      </Pill>
    ) : null}
  </div>
);

export const QueryFilters = <T extends FilterInput>({
  bulkActions,
  emptyParams,
  filters,
  onOpenFlyover,
  onUpdateParams,
  filterText = "Filters",
  params,
  queries,
  searchParamKey,
  searchPlaceholder = "Search",
  selectedCount,
  totalElements,
  horizontalPadding,
}: QueryFiltersProps<T>) => {
  const expandSearch = useBoolean(false);
  const searchParamValue = searchParamKey ? ((params[searchParamKey] as string | undefined) ?? "") : "";
  const [searchString, setSearchString] = useState(searchParamValue);

  useEffect(() => {
    // If the page is already rendered, but another event (say the search command palette) updates the search param, this value must be relayed to the state
    setSearchString(searchParamValue);
  }, [searchParamValue]);

  const updateSearch = useDebouncedCallback((value: string) => {
    if (searchParamKey) {
      onUpdateParams({ [searchParamKey]: value } as unknown as Partial<T>);
    }
  }, SEARCH_DEBOUNCE_DELAY_MS);

  const handleSearch = useCallback(
    (value: string) => {
      setSearchString(value);
      updateSearch(value);
    },
    [updateSearch]
  );

  const handleUpdateParams = useCallback(
    (keyValues: Partial<T>) => {
      if (searchParamKey && searchParamKey in keyValues && keyValues[searchParamKey] === undefined) {
        setSearchString("");
      }

      onUpdateParams(keyValues);
    },
    [onUpdateParams, searchParamKey]
  );

  return (
    <ActionsBarContainer
      horizontalPadding={horizontalPadding}
      searchFilter={
        <>
          {searchParamKey && (
            <FormFieldInput
              className={cx(
                "transition-all duration-50 ease-in",
                expandSearch.isOn || searchPlaceholder.length > 10 || searchString ? "w-44" : "w-24"
              )}
              Icon={searchString ? RemoveIcon : SearchIcon}
              iconOnClick={() => handleSearch("")}
              onBlur={expandSearch.off}
              onChange={(e) => handleSearch(e.target.value)}
              onFocus={expandSearch.on}
              placeholder={searchPlaceholder}
              value={searchString}
            />
          )}
          {onOpenFlyover && (
            <Button
              className="flex items-center gap-x-1"
              onClick={onOpenFlyover}
              size={filterText ? "small" : "smallSquare"}
              theme="tertiary"
            >
              {filterText}
              <Icon SvgIcon={FiltersIcon} />
            </Button>
          )}
        </>
      }
    >
      {bulkActions ? (
        <>
          <BulkActions selectedCount={selectedCount}>{bulkActions}</BulkActions>
          <div className="h-8 flex items-center">
            <VerticalDivider className="mx-1" size="md" />
          </div>
        </>
      ) : null}

      <QueryFilterPills
        emptyParams={emptyParams}
        filters={filters}
        numResults={totalElements}
        onClearAll={handleUpdateParams}
        onUpdateParams={handleUpdateParams}
        params={params}
        queries={queries}
        version="v2"
      />
    </ActionsBarContainer>
  );
};
