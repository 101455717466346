import { forwardRef } from "react";
import { cx } from "@libs/utils/cx";
import { useEnsureId } from "@libs/hooks/useEnsureId";
import { FormField, FormFieldProps } from "@libs/components/UI/FormField";
import { cxFormFieldStyle } from "@libs/components/UI/formFieldStyle";
import {
  formatNumber,
  formatPercentNumber,
  NumberInput,
  NumberInputProps,
} from "@libs/components/UI/NumberInput";
import { FormatReadOnlyValue } from "@libs/components/UI/FormFieldInput";
import { isDefined } from "@libs/utils/types";

interface BaseProps {
  Icon?: IconComponent;
  displayErrorMessage?: boolean;
  inputClassName?: string;
  inputWrapperClassName?: string;
  formatReadOnlyValue?: FormatReadOnlyValue;
  containerClassName?: string;
}

export type FormFieldNumberInputProps = BaseProps &
  FormFieldProps &
  Omit<NumberInputProps, keyof FormFieldProps>;

const formatDisplayValue = (
  value: FormFieldNumberInputProps["value"],
  format?: FormFieldNumberInputProps["format"]
) => {
  if (value || value === 0) {
    return format === "percent" ? formatPercentNumber(`${value}`) : formatNumber(`${value}`);
  }

  return "-";
};

export const FormFieldNumberInput = forwardRef<HTMLInputElement, FormFieldNumberInputProps>(
  (
    {
      disabled,
      required,
      label,
      error,
      Icon,
      className,
      children,
      value,
      id,
      edit = true,
      displayErrorMessage = true,
      formatReadOnlyValue,
      inputClassName,
      inputWrapperClassName,
      containerClassName,
      ...rest
    },
    ref
  ) => {
    const fieldId = useEnsureId({ customId: id });
    const controlStyles = cxFormFieldStyle.control({
      hasIcon: Boolean(Icon),
    });

    return (
      <FormField
        disabled={disabled}
        required={required}
        label={label}
        error={error}
        className={className}
        containerClassName={containerClassName}
        edit={edit}
        displayErrorMessage={displayErrorMessage}
        id={fieldId}
      >
        {edit ? (
          <div className={cx(cxFormFieldStyle.wrapper, inputWrapperClassName)}>
            <NumberInput
              id={fieldId}
              ref={ref}
              value={value}
              disabled={disabled}
              className={cx(controlStyles, cxFormFieldStyle.input, inputClassName)}
              {...rest}
            />
            {Icon ? (
              <div className={cxFormFieldStyle.iconContainer()}>
                <Icon role="img" className={cxFormFieldStyle.icon({ disabled })} />
              </div>
            ) : null}
            {children}
          </div>
        ) : (
          <span className={cxFormFieldStyle.controlValueOnly}>
            {formatReadOnlyValue
              ? formatReadOnlyValue(isDefined(value) ? `${value}` : undefined, fieldId)
              : formatDisplayValue(value, rest.format)}
          </span>
        )}
      </FormField>
    );
  }
);
