export const StatusCodes = {
  UNAUTHORIZED: 401,
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
  REQUEST_TIMEOUT: 408,
  CONFLICT: 409,
  FORBIDDEN: 403,
  TOO_EARLY: 425,
  TOO_MANY_REQUESTS: 429,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
  SERVICE_UNAVAILABLE: 503,
  GATEWAY_TIMEOUT: 504,
  SUCCESS: 200,
};

export const retryCodes = new Set([StatusCodes.BAD_GATEWAY, StatusCodes.SERVICE_UNAVAILABLE]);
