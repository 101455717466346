import { PropsWithChildren } from "react";
import { cx } from "@libs/utils/cx";

type Props = PropsWithChildren<{
  selected?: boolean;
  step: number;
}>;
export const TaskStep: React.FC<Props> = ({ selected = false, step, children }) => {
  const numberContent = (
    <div
      className={cx(
        `rounded-full
         h-6
         w-6
         flex
         items-center
         justify-center
         text-center`,
        selected ? "bg-blue-200 text-blue-500" : "bg-white outline outline-greyLightest"
      )}
    >
      {step}
    </div>
  );

  if (!children) {
    return numberContent;
  }

  return (
    <div className="flex items-center gap-2">
      {numberContent}
      <div
        className={cx(
          "text-sm font-sansSemiBold text-primaryTheme",
          selected ? "text-primaryTheme" : "text-slate-500"
        )}
      >
        {children}
      </div>
    </div>
  );
};
