import { FC, useMemo } from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { InputsHeaderRow, Title, TitleContainer } from "@libs/components/UI/GridTableComponents";
import { PracticeMigrationSettingsVO } from "@libs/api/generated-api";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { StatusCodes } from "@libs/utils/statusCodes";
import { AsyncSection } from "@libs/components/UI/AsyncSection";
import { getPracticeMigrationSettings } from "api/practice/queries";
import { usePathParams } from "hooks/usePathParams";
import { MigrationSettingsForm } from "components/Practice/MigrationSettings/MigrationSettingsForm";
import { useEditPracticeSettings } from "components/Practice/hooks/useEditPracticeSettings";

export const MigrationSettingsRoute: FC = () => {
  const { practiceId } = usePathParams("practice");
  const { handleEditingChanged, isEditing } = useEditPracticeSettings();
  const [practiceMigrationSettingsQuery] = useApiQueries([
    getPracticeMigrationSettings({
      args: { practiceId },
    }),
  ]);
  const { data, error, isLoading } = practiceMigrationSettingsQuery;

  const migrationSettings: Omit<PracticeMigrationSettingsVO, "practiceId" | "practiceUuid"> = useMemo(
    // In the case of a 404, migration settings don't exist yet so we default to empty values
    () => data ?? {},
    [data]
  );

  return (
    <>
      <InputsHeaderRow className="flex justify-between">
        <TitleContainer className="flex flex-row justify-between">
          <Title>Migration Settings</Title>
        </TitleContainer>
        {!isEditing && (
          <ButtonIcon SvgIcon={EditIcon} theme="primary" onClick={() => handleEditingChanged(true)} />
        )}
      </InputsHeaderRow>
      <AsyncSection
        isLoading={isLoading}
        isLoadingError={Boolean(error && error.status !== StatusCodes.NOT_FOUND)}
      >
        <MigrationSettingsForm
          key={`${isEditing}`}
          edit={isEditing}
          practiceId={practiceId}
          settings={migrationSettings}
          onCloseEditor={() => handleEditingChanged(false)}
        />
      </AsyncSection>
    </>
  );
};
