/**
 *
 * @param constantStr {string} a constant-looking string, such as `PAYMENT_INFORMATION`
 * @returns {string} the input is lower-cased and underscores are replaced by spaces, such as `payment information`
 */
export const humanizeConstant = (constantStr: string) => {
  return constantStr.toLowerCase().replaceAll("_", " ");
};

/**
 *
 * @param message {string} a string, such as `payment information`
 * @returns {string} the input is sentence-cased, such as `Payment information`
 */
export const sentenceCase = (message: string) => {
  return message.charAt(0).toUpperCase() + message.slice(1).toLowerCase();
};

/**
 *
 * @param constantStr {string} a constant-looking string, such as `PAYMENT_INFORMATION`
 * @returns {string} a shorthand to calling `humanizeConstant()` followed by `sentenceCase()`, such as `Payment information`
 */
export const sentenceCaseConstant = (constantStr: string) => {
  return sentenceCase(humanizeConstant(constantStr));
};

/**
 *
 * @param constantStr {string} a constant-looking string, such as `PAYMENT_INFORMATION`
 * @returns {string} Works like `sentenceCase()` but the first letter of every word is upper-cased.
 */
export const titleCaseConstant = (constantStr: string) => {
  return humanizeConstant(constantStr).split(" ").map(sentenceCase).join(" ");
};
