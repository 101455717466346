import { forwardRef } from "react";
import { cx } from "@libs/utils/cx";

export const cxPillStyles = {
  themes: {
    // for the old design system until we're off
    whiteGreyLighter: "bg-white ring-1 ring-inset ring-greyLighter",
    white: "bg-white ring-1 ring-inset ring-greyLightest",
    grey: "bg-greyLightest text-greyDark",
    magenta: "bg-magentaLight text-magentaDark",
    red: "bg-red-200 text-red-800",
    orange: "bg-orange-200 text-orange-800",
    yellow: "bg-yellow-200 text-yellow-800",
    green: "bg-green-200 text-green-800",
    blue: "bg-blue-200 text-blue-500",
    violet: "bg-violet-200 text-violet-800",
    slate100: "bg-slate-100 text-slate-900",
    slate200: "bg-slate-200 text-slate-900",
    greyDark: "bg-greyDark/10 text-greyDark",
    primaryTheme: "bg-primaryTheme/10 text-primaryTheme",
    greyMedium: "bg-greyMedium/10 text-greyMedium",
    secondaryTheme: "bg-secondaryTheme/10 text-secondaryTheme",
    selected: "bg-actionLight text-greyDark ring-1 ring-inset ring-primaryTheme",
    disabled: "bg-slate-50 text-slate-400 ring-1 ring-inset ring-slate-200",
    custom: "",
  },
  sizes: {
    xsSquare: "text-xs/4 py-0.5 px-0.5 h-6",
    xs: "text-xs/4 py-0.5 px-1.5 h-6",
    sm: "text-xs/4 py-1 px-2 h-6",
    md: "text-xs/4 py-2 px-3 h-8",
    lg: "text-sm p-3 h-10",
  },
};

export type PillSize = keyof typeof cxPillStyles.sizes;
export type PillTheme = keyof typeof cxPillStyles.themes;
export interface PillProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  children: React.ReactNode;
  theme?: PillTheme;
  round?: boolean;
  size?: PillSize;
}

export const Pill = forwardRef<HTMLDivElement, PillProps>(
  ({ className, theme = "white", size = "sm", round = true, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cx(
          "text-center whitespace-nowrap",
          className,
          round ? "rounded-full" : "rounded",
          cxPillStyles.themes[theme],
          cxPillStyles.sizes[size]
        )}
        {...props}
      />
    );
  }
);
