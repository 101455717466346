import { CurrencyLocales, centsToDollars, getCurrencyFormatter } from "@libs/utils/currency";
import { getLocalDate } from "@libs/utils/date";
import { emailRegex } from "@libs/utils/regex";

export const validateEmail = (value: string) => {
  if (emailRegex({ exact: true }).test(value)) {
    return true;
  }

  return false;
};

/**
 * Calculates the age based on the given date of birth.
 *
 * @param {Date} now - A date representing now in the practice timezone
 * @param {string} birthday - A date string with format YYYY-MM-DD representing the date of birth.
 * @returns {number} Returns the calculated age.
 */
export const getAgeByDob = (now: Date, str: string) => {
  if (str) {
    const birthDate = getLocalDate(str);
    let age = now.getFullYear() - birthDate.getFullYear();
    const m = now.getMonth() - birthDate.getMonth();

    if (m < 0 || (m === 0 && now.getDate() < birthDate.getDate())) {
      age--;
    }

    return age;
  }

  return 0;
};

export const formatFullNameToInitials = ({ fullName }: { fullName: string | undefined }) => {
  if (!fullName) {
    return "";
  }

  const hasNameTitle = fullName.includes(". ");

  const nameArr = hasNameTitle ? (fullName.split(". ")[1] ?? "").split(" ") : fullName.split(" ");

  const initials = nameArr.reduce((prev, curr) => (curr[0] ? prev + curr[0] : prev), "");

  return initials.length > 1 ? `${initials[0]}${initials.at(-1)}` : initials;
};

export const formatBytes = (bytes: number, decimals = 1) => {
  if (bytes === 0) {
    return "0 Bytes";
  }

  const k = 1000;
  const dm = Math.max(decimals, 0);
  const sizes = ["bytes", "kb", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${Number.parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const listToText = (list: string[], conjunctionWord: "or" | "and" = "or") => {
  if (list.length < 3) {
    return list.join(` ${conjunctionWord} `);
  }

  return `${list.slice(0, -1).join(", ")}, ${conjunctionWord} ${list.at(-1)}`;
};

const UNITS = ["", "K", "M", "B"];

export const formatNumber = (
  n: number,
  options?: { isCurrency: boolean; languageCode?: CurrencyLocales }
) => {
  const formatter = getCurrencyFormatter(options?.languageCode);
  const { isCurrency } = options ?? { isCurrency: false };
  let num = isCurrency ? centsToDollars(n) : n;
  const sign = Math.sign(num);
  let unit = 0;

  while (Math.abs(num) > 999) {
    unit += 1;
    num = Math.round(Math.abs(num) / 100) / 10;
  }

  const absoluteValue = Math.abs(num);
  const numericPart = isCurrency ? formatter.format(absoluteValue).split(".")[0] : absoluteValue;

  return `${sign < 0 ? "-" : ""}${numericPart}${UNITS[unit]}`;
};
